<template>
    <div class="goods flex jc-fd">
        <div
            class="goods-item flex ai-c"
            @click="Click"
            @touchstart="touchS"
            @touchmove="touchM"
            @touchend="touchE"
            :style="{ left: x + 'px' }"
        >
            <img class="goodsImg" :src="item.goodsPic" alt="" />
            <div class="xq">
                <div class="goods-name flex ai-c">
                    <!-- <font class="font flex ai-c">包邮</font> -->
                    <div class="wb line">{{ item.goodsName }}</div>
                </div>
                <div class="specifications lines">
                    {{ item.collect ? item.collect : 0 }}人收藏
                </div>
                <div class="money">¥{{ item.goodsPrice.toFixed(2) }}</div>
            </div>
            <!-- <img class="cart" @click="addCart(item)" src="/img/cart_add_icon.png" alt=""> -->
        </div>
        <div class="btn-box flex">
            <div
                class="btn flex ai-c jc-c"
                @click.stop="collectRemove(item.goodsId)"
            >
                删除
            </div>
        </div>
    </div>
</template>

<script>
import { collectRemove } from "../../../api";
export default {
    components: {},
    props: ["item"],
    data() {
        return {
            clientX1: "",
            clientX2: "",
            goodsX: 0,
            x: 0,
        };
    },
    watch: {},
    computed: {},
    methods: {
        Click() {
            if (this.x != 0) {
                this.x = 0;
            } else {
                this.$router.push({
                    path: "/home/details",
                    query: { id: this.item.goodsId },
                });
            }
        },
        // touch事件，显示删除
        touchS(e) {
            this.clientX1 = e.touches[0].clientX;
        },
        touchM(e) {
            this.clientX2 = e.touches[0].clientX;
            var x = this.clientX1 - this.goodsX - this.clientX2;
            x = x < 0 ? 0 : x;
            this.x = -x;
        },
        touchE(e) {
            var domW =
                document.getElementsByClassName("btn-box")[0].offsetWidth;
            this.goodsX = -this.x > domW / 2 ? -domW : 0;
            this.x = this.goodsX;
        },
        //删除收藏
        collectRemove(id) {
            this.$dialog
                .confirm({
                    title: "提示",
                    message: "确认取消收藏该商品",
                })
                .then(() => {
                    collectRemove({
                        goodsId: id,
                    }).then((res) => {
                        
                        this.$emit('back')
                        
                        this.$toast.success({
                            duration: 1500,
                            message: "取消收藏成功",
                        });
                    });
                });
        },
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
.goods {
    position: relative;
    width: 7.5rem;
    height: 2.2rem;
    background: #ffffff;
    overflow: hidden;
    border-bottom: 0.2rem solid #f5f5f5;
}
.goods-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: left 0.1s;
    /* transition-duration:0.1s; */
}
.btn-box {
    width: 1.5rem;
    height: 100%;
}
.btn-box .btn {
    width: 1.5rem;
    height: 100%;
    background-color: rgba(247, 162, 71, 1);
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
}
.btn-box .btn:nth-child(1) {
    background-color: rgba(243, 61, 61, 1);
}
.goods-item .goodsImg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    object-fit: cover;
}
.goods-item .xq {
    width: 5rem;
}
.goods-item .goods-name {
    width: 100%;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
    margin-top: 0.1rem;
}
.goods-item .goods-name .font {
    width: min-content;
    white-space: nowrap;
    font-size: 0.22rem;
    line-height: 0.22rem;
    color: #e8542e;
    padding: 0.02rem 0.12rem;
    border-radius: 0.3rem;
    border: 1px solid #e8542e;
    margin-right: 0.12rem;
}
.goods-item .specifications {
    width: 100%;
    line-height: 0.56rem;
    font-size: 0.24rem;
    line-height: 0.28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-top: 0.1rem;
}
.goods-item .money {
    font-size: 0.34rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f25e38;
    margin-top: 0.35rem;
}
</style>