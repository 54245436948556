<template>
    <div class="collection">
        <div class="goods-nb">共{{total}}件宝贝</div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="list"
        >
            <template  v-for="(item, index) in list">
              <v-goods :item="item" @back="back" :key="index"/>
            </template>
            
        </van-list>
        <div class="kong"></div>
        <div class="btn-bottom" @click="$router.push({path: '/home'})">返回商城</div>
  </div>
</template>

<script>
import goods from './goods.vue'
import { cartPage, addCart } from '../../../api'
export default {
  components: {
    'v-goods': goods
  },
  props: {},
  data() {
    return {
        
        current: 1,
        total: 0,
        list: [],
        loading: false,
        finished: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    back() {
      this.list = [];
      this.current = 1;
      this.cartPage()
    },
    cartPage() {
      cartPage({
        current: this.current,
        size: 20
      }).then(res=>{
         // 加载状态结束
        this.loading = false;
        this.current++
        var list = res.data.data.records
        this.total = res.data.data.total
        if (list.length==0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        for (var item of list) {
          item.goodsPic = item.goodsPic?item.goodsPic.split(',')[0]:''
          this.list.push(item)
        }
      })
    },
    onLoad() {
      this.cartPage()
    },
    //加入购物车
    addCart (id) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
      })
      addCart({
        id: id
      }).then(res=>{
        this.$toast.success({
            message: '加入购物车成功',
            duration: 1500,
            forbidClick: true,
        })
      }).catch((res)=>{
        this.$toast.clear()
      })
    },
    
  },
  created() {
  },
  mounted() {},
};
</script>
<style scoped>
.collection{
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    padding-top: .7rem;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
}
.goods-nb{
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    line-height: .7rem;
    box-sizing: border-box;
    padding-left: .3rem;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
    z-index: 100;
    background-color: RGBA(238, 238, 238, 1);
}
.list{
    width: 100%;
}

.cart{
    position: absolute;
    bottom: .3rem;
    right: .2rem;
    width: .6rem;
    height: .6rem;
}
.btn-bottom{
  position: fixed;
  left: calc(50% - 3.45rem);
  bottom: .24rem;
  width: 6.90rem;
  height: .90rem;
  background: #F35E3A;
  border-radius: .45rem;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  line-height: .9rem;
  text-align: center;
}
</style>